import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { siteMetadata } from "../../gatsby-config";
import Layout from "../components/layout";
import {
  hero,
  intro,
  overviewList,
  overviewList__more_link,
  subtitle,
  title,
} from "../components/project.module.scss";

const ProjectVietRefugee = () => {
  const imgVariants = {
    animate: { opacity: 1, scale: 1, y: 0 },
    initial: {
      opacity: 0,
      scale: 0.95,
      y: 0,
    },
  };
  return (
    <>
      <Helmet>
        <body className="dark-header" />
        <title>Viet Refugee</title>
        <link rel="canonical" href={siteMetadata.siteUrl} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <div className="container-fluid" style={{ padding: 0 }}>
          <div style={{ background: "#a62639" }}>
            <div className="row">
              <motion.div
                className={title}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 1,
                  },
                }}
              >
                <h1 style={{ color: "#ffffff" }}>VietRefugee.com</h1>
              </motion.div>
              <div className="col-12">
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 1,
                    },
                  }}
                >
                  <section className={intro}>
                    <h2 className={subtitle} style={{ color: "#ffffff" }}>
                      A Vietnamese Boat Refugee Story | Personal Project
                    </h2>
                  </section>
                </motion.div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <motion.div
                  variants={imgVariants}
                  initial="initial"
                  animate="animate"
                  transition={{ duration: 0.8, ease: "easeIn" }}
                >
                  <a
                    href="https://vietrefugee.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      src="../images/teaser-viet-refugee.webp"
                      quality="100"
                      layout="fullWidth"
                      placeholder="blurred"
                      className={hero}
                      alt="Desktop design of website"
                    />
                  </a>
                </motion.div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <section className={overviewList}>
                  <div className="col-md-6">
                    <h3 style={{ color: "#ffffff" }}>Project</h3>
                    <p style={{ color: "#ffffff" }}>
                      In this project, I've utilized parallax effects, typography
                      design, and personal family photos to narrate the story of my father's escape from post-war Vietnam by boat in 1988, after his fifth attempt. Through sharing his story, we also hope to facilitate his reunion with his long-lost friend, Dung Ly, who, like him, braved the difficult journey as one of the boat people.
                    </p>
                    <a
                      href="https://vietrefugee.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <motion.div
                        className={overviewList__more_link}
                        whileHover={{
                          scale: 1.06,
                          originX: 0,
                          color: "#165cac",
                        }}
                      >
                        <h4 style={{ color: "#ffffff" }}>
                          Visit Site
                          <span className="icon-ext">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 32"
                            >
                              <path d="M 18 5 L 18 7 L 23.5625 7 L 11.28125 19.28125 L 12.71875 20.71875 L 25 8.4375 L 25 14 L 27 14 L 27 5 Z M 5 9 L 5 27 L 23 27 L 23 14 L 21 16 L 21 25 L 7 25 L 7 11 L 16 11 L 18 9 Z" />
                            </svg>
                          </span>
                        </h4>
                      </motion.div>
                    </a>
                  </div>
                  <div className="col-md-6">
                    <h3 style={{ color: "#ffffff" }}>Tags</h3>
                    <p style={{ color: "#ffffff" }}>
                      Digital Storytelling, Parallax Effect, Typography Design
                    </p>
                    <h3 style={{ color: "#ffffff" }}>Tools</h3>
                    <p style={{ color: "#ffffff" }}>
                      Create React App, React Scroll Parallax, HTML/CSS,
                      Photoshop
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProjectVietRefugee;
